<template>
    <div class="second">
		<div class="header">
			

			<el-popover
				placement="bottom"
				:width="300"
				trigger="hover"
			>
				<template #reference>
					<a href="javascript:;">
						<img :style="headerImgH" src="@/assets/img/dissDao/5.png" alt="">
					</a>
				</template>
				<div>
					<img width="300" src="@/assets/img/dissDao/QRcode.jpg" alt="">
				</div>
			</el-popover>

			<a href="https://discord.gg/gtBPaKafDv" target="_blank"><img :style="headerImgH" src="@/assets/img/dissDao/2/img_03.png" alt=""></a>
			<a href="https://twitter.com/DissDao_Doomer" target="_blank"><img :style="headerImgH" src="@/assets/img/dissDao/2/img_04.png" alt=""></a>
			<a :href="openseaUrl" target="_blank"><img :style="headerImgH" src="@/assets/img/dissDao/2/img_05.png" alt=""></a>
			<a href="javascript:;" @click="awaken">
				<img v-if="off" :style="headerImgH" src="@/assets/img/dissDao/music_off.png" alt="">
				<img v-else :style="headerImgH" src="@/assets/img/dissDao/music.png" alt="">
			</a>
		</div>
		<div class="page-1" :style="pageOne">

			<div class="msg-main" :style="msgMain">
				<div class="msg-scroll" :style="scroll">
					<el-image class="img-msg" :src="msg_img" :fit="fit" />
				</div>
			</div>
			<div class="diss-dao" :style="dissDao">
				<img :style="disDaoGif" class="diss-doa-gif" src="@/assets/img/dissDao/2/diss-dao.gif" alt="">
                <img :style="disDaoImg" @click="goNext" class="sucker" src="@/assets/img/dissDao/2/sucker.png" alt="">
			</div>

		</div>
	</div>
</template>

<script>
	import msg from '@/assets/img/dissDao/2/1.png'
    export default {
        data() {
            return {
                msg_img: msg,
                headerImgH: {},
                pageOne: {},
                scroll: {},
                msgMain: {},
                dissDao: {},
                disDaoGif: {},
                disDaoImg: {},
				openseaUrl: process.env.VUE_APP_OPENSEA_URL
            }
        },
		computed: {
			off() {
				return this.$store.state.off;
			}
		},
        methods: {
            goNext() {
                this.$router.push("/third")
            },
            getValue(val, type) {
                const body_h = document.getElementsByTagName('body')[0].clientHeight;
                return type ? body_h * (val/1177) : body_h * (val/1177) + 'px';
            },
            init() {
                this.pageOne = {
                    height: this.getValue(906),
                    width: this.getValue(1161)
                }
                this.scroll = {
                    height: this.getValue(625),
                    width: this.getValue(400),
                    marginTop: this.getValue(230),
                    paddingLeft: this.getValue(55),
                }
                this.msgMain = {
                    width: this.getValue(450),
                }
                this.dissDao = {
                    width: this.getValue(474),
                }
                this.disDaoGif = {
                    width: this.getValue(520),
                    top: this.getValue(100)
                }
                this.disDaoImg = {
                    width: this.getValue(474),
                    top: this.getValue(250)
                }
                this.headerImgH = {
                    height: this.getValue(88)
                }
                console.log('this.headerImgH', this.headerImgH)
            },
			awaken(){
				this.$store.dispatch('awaken')
			},
        },
        mounted () {
            this.init();
            window.onresize = ()=>{
                this.init();
            }
        },
    }
</script>

<style lang="less" scoped>
    .second{
		width: 100%;
		min-height: 100vh;
		// height: 1177px;
		position: relative;
		background: url('./../../assets/img/dissDao/2/bg.png') no-repeat center;
		background-size: cover;
		.header{
			padding-top: 30px;
			padding-bottom: 20px;
			a{
				margin-right: 10px;
			}
		}
		.page-1{
			margin: 0 auto;
			background: url('./../../assets/img/dissDao/2/img_06.png') no-repeat center;
			background-size: cover;
			display: flex;
			justify-content: space-between;
			.msg{
				&-main{
					overflow: hidden;
					transform: rotate(-1.2deg);
				}
				&-scroll{
					overflow: auto;
					padding-right: 28px;
				}
			}
			.diss-dao{
				position: relative;
				.diss-doa-gif{
					position: absolute;
					left: 0;
				}
				.sucker{
					position: absolute;
					left: 0;
					cursor: pointer;
				}
			}
		}
	}
</style>